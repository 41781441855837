<template>
	<div>
        <a-row type="flex" :gutter="[2, 2]">
            <a-divider orientation="left">
				<span style="color:black; font-size:18px;">Roles de usuarios</span> <span style="color:#9C9C9C; font-size:12px;">Visualiza la lista de roles</span>
			</a-divider>

            <a-col flex="1 1 100px">
				<a-button
                    type="primary"
                    block
                    @click="openModal('agregar', null)"
                    >
                    Nuevo Rol Usuario
                </a-button>
            </a-col>
            
            <a-col flex="1 1 100px">
                
            </a-col>

            <a-col flex="1 1 200px">
				
            </a-col>

            <a-col flex="1 1 200px">
				
			</a-col>

            <a-col flex="1 1 400px">
                <a-input-search placeholder="Buscar rol en el sistema" @search="onSearch">
                    <a-button type="primary" slot="enterButton">
                        Buscar
                    </a-button>
                </a-input-search>
            </a-col>

            <a-col :span="1">
                <a-button @click="obtenerListadoActual">
                    <a-icon type="reload" />
                </a-button>
            </a-col>
        </a-row>

        <br>
        
        <a-table 
            v-if="$can('funcion.consultar.roles')"
            :columns="columns" 
            :data-source="roles" 
            :pagination="paginate"
            :loading="loading"
            :ellipsis="true"
            size="small"
            :rowKey="record => record.id"
            :scroll="{ x: 980 }"
            @change="handlePagination"
            >

            <template slot="permisos" slot-scope="item">
                <template v-if="Object.entries(item.permissions).length > 0">
                    <a-tag color="green">
                        PERMISOS ASIGNADOS
                    </a-tag>
                </template>

                <template v-if="Object.entries(item.permissions).length === 0">
                    <a-tag color="orange">
                        PERMISOS NO ASIGNADOS
                    </a-tag>
                </template>
            </template>

            <template slot="fechaCreacion" slot-scope="item">
                {{ utils.formatDate(item.created_at) }}
            </template>

            <template slot="fechaActualizacion" slot-scope="item">
                {{ utils.formatDate(item.updated_at) }}
            </template>

            <span slot="expandedRowRender" slot-scope="item" style="margin: 0">
                <span style="font-weight:700; font-size:17px;">DATOS DE REGISTRO</span>
                <a-row>
                    <a-col :span="12">
                        <a-list size="small" bordered><a-list-item>ID:</a-list-item></a-list>
                    </a-col>
                    <a-col :span="12">
                        <a-list size="small" bordered><a-list-item>{{ item.id }}</a-list-item></a-list>
                    </a-col>
                </a-row>
            </span>

            <template slot="action" slot-scope="item">
                <a-dropdown>
                    <a-menu slot="overlay">
                        <a-menu-item @click.once="openModal('editar', item)" :key="buttonKeyEditar" v-if="$can('funcion.editar.roles')">
                            <a-tooltip placement="topLeft">
                                <template slot="title">
                                    <span style="font-size:11px;">ID: {{ item.id }}</span>
                                </template>
                                <a-icon type="form" :style="{ fontSize: '18px', color: '#006ccc' }"/> <strong>Editar Rol</strong>
                            </a-tooltip>
                        </a-menu-item>

                        <a-menu-item v-if="$can('funcion.eliminar.roles')">
                            <a-popconfirm
								title="¿Estás seguro(a) de eliminar este registro?"
								ok-text="Si"
								cancel-text="No"
								placement="topLeft"
								@confirm="eliminar(item.id)"
								>
								<a-tooltip>
									<template slot="title">
										<span>ID: {{ item.id }}</span>
									</template>
									<a-icon type="delete" :style="{ fontSize: '18px', color: '#cc2400' }"/> <strong>Eliminar Rol</strong>
								</a-tooltip>
							</a-popconfirm>
                        </a-menu-item>
                    </a-menu>
                    <a-button> Acciones <a-icon type="down" /> </a-button>
                </a-dropdown>
            </template> 
        </a-table>

        <a-modal
			v-model="modalRol"
			title="GESTION DE PERMISOS"
			:closable="true"
			:destroyOnClose="true"
			:maskClosable="false"
			:dialog-style="{ top: '5px' }"
			:width="680"
			:zIndex="1049"
			>

			<div class="row mb-12">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Nombre del Rol: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                            <a-input size="large" v-model="form.nombre"  placeholder="Nombre del Rol" onfocus="myFunction(this)" style="width: 100%" />
                        </div>

                        <span style="color:black; font-weight: 700; font-size: 15px;"><center>Asignar Permisos al Rol</center></span>
                        
                        <a-tabs type="card" :default-active-key="`usuarios`" tab-position="left">
                            <a-tab-pane v-for="item in permisos" :key="item.nombre">
                                <span slot="tab">
                                    {{ item.nombre.toUpperCase() }}
                                     <a-tag color="blue">
                                        {{ Object.entries(item.permisos).length }}
                                    </a-tag>
                                </span>
                                <span>{{ item.descripcion }}</span><br>
                                <span v-for="(option, key) in item.permisos" :key="key">
									<p-check 
                                        class="p-default p-curve p-pulse p-bigger" 
                                        color="primary" 
                                        focus 
                                        :name="'id'+ option.id" 
                                        :value="[{'id': option.id}]" 
                                        v-model="form.respuesta[option.id]"
                                        >
                                        <span style="color:black;">&nbsp;{{ key + 1 }}. Habilitar {{ utils.quitarPuntosYCapitalizar(option.name) }}</span>
                                    </p-check>
                                    <br>
								</span>
                            </a-tab-pane>
                        </a-tabs>

                        <br>

                        <a-button
                            v-if="tipomodal === 'agregar'" 
                            size="large" 
                            type="primary"
                            block
                            @click.once="registrar()" :key="buttonKey"
                            >
                            REGISTRAR NUEVO ROL
                        </a-button>

                        <a-button
                            v-if="tipomodal === 'editar'" 
                            size="large" 
                            type="primary"
                            block
                            @click.once="editar()" :key="buttonKeyEditar"
                            >
                            ACTUALIZAR ROL
                        </a-button>
                    </div>	
                </div>
			</div>
					
			<template slot="footer">
				<a-button key="back" @click="modalRol = false">
					Cerrar Modal
				</a-button>
			</template>
		</a-modal>
    
        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
	</div>
</template>

<script>
    const columns = [
        { 
            id: 'id',
            title: 'Id', 
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'id',
            title: 'Nombre Rol', 
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'id',
            title: 'Permisos', 
            dataIndex: '',
            key: 'permisos',
            scopedSlots: { customRender: 'permisos' },
            width: '20%'
        },
        { 
            id: 'id',
            title: 'F.Creacion', 
            dataIndex: '',
            key: 'created_at',
            scopedSlots: { customRender: 'fechaCreacion' },
            width: '20%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'id',
            title: 'F.Actualizacion', 
            dataIndex: '',
            key: 'updated_at',
            scopedSlots: { customRender: 'fechaActualizacion' },
            width: '20%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'id',
            title: 'Acciones', 
            dataIndex: '',
            scopedSlots: { customRender: 'action' },
            width: '15%'
        }
    ]

    import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import { mapGetters, mapActions } from 'vuex'
	import * as utils from '@/utils/utils'
	import router from '@/config/router'

	export default {

		props: {
            config: { required: true },
            usuario: { required: true }
        },

		data () {
			return {
                dateFormat: 'YYYY-MM-DD',
                utils,
				moment,
				locale,
                columns,
                showModal: false,
                modalRol: false,
                spinnerloading: false,
                loading: false,
                tipomodal: '',
                buttonKey: 10,
                buttonKeyEditar: 20,
                buttonKeyEliminar: 30,
                form: {
                    nombre: '',
                    respuesta: []
                },
                paginate: {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'id',
                    order: 'descend'
                }
			}
		},

		computed: {
			...mapGetters('role', ['roles', 'permisos'])
		},

		methods: {
			...mapActions('role', ['createRole', 'updateRole', 'getRoles', 'deleteRole', 'getPermission']),

            limpiarDatos () {
                this.form = {}
				this.form.nombre = ''
				this.form.respuesta = []
			},

            registrar () {
                this.spinnerloading = true
				this.createRole(this.form)
                .then(response => {
                    if (response.status === 201) {
                        this.paginarResultados()
					    utils.openNotificationWithIcon('success', 'Exito', response.data.message, 'topRight')
                        this.modalRol = false
                        this.spinnerloading = false
                    }
				})
                .catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKey++
			},

			editar () {
                this.spinnerloading = true
				this.updateRole(this.form)
                .then(response => {
                    if (response.status === 201) {
					    this.paginarResultados()
                        utils.openNotificationWithIcon('success', 'Exito', response.data.message, 'topRight')
                        this.modalRol = false
                        this.spinnerloading = false
                    }
				})
                .catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKeyEditar++
			},

			eliminar (id) {
                this.spinnerloading = true
				this.deleteRole(id)
                .then(response => {
                    if (response.status === 201) {
                        this.paginarResultados()
                        utils.openNotificationWithIcon('success', 'Exito', response.data.message, 'topRight')
					    this.spinnerloading = true
                    }
				})
                .catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
			},

            obtenerListadoActual (value) {
                this.paginarResultados()
            },

            onSearch (value) {
            },

			openModal (tipo, data) {
                this.limpiarDatos()
                this.spinnerloading = true
				this.tipomodal = tipo

				if (tipo === 'editar') {
                    const nuevoObj = {}
                    const permisos = data.permissions.map((item, index) => {
                        return item.id
                    })
                    for (const clave in permisos) {
                        const valor = permisos[clave]
                        nuevoObj[valor] = true
                    }
					
                    setTimeout(function () {
                        this.form.id = data.id
					    this.form.nombre = data.name
                        this.form.respuesta = nuevoObj

                        this.buttonKeyEditar++
                        this.modalRol = true
                        this.spinnerloading = false
                    }.bind(this), 300)
				} else if (tipo === 'agregar') {
                    setTimeout(function () {
                        this.buttonKey++
                        this.modalRol = true
                        this.spinnerloading = false
                    }.bind(this), 300)
                }
			},

            /** PAGINACION */
            handlePagination (paginate, filters, sorter) {
                this.paginate = {
                    pageSize: paginate.pageSize,
                    current: paginate.current,
                    total: paginate.total,
                    field: (sorter.field) ? sorter.field : 'id',
                    order: (sorter.order) ? sorter.order : 'descend'
                }
                this.paginarResultados()
            },

            changePaginate (data) {
                this.paginate = {
                    pageSize: data.per_page,
                    current: data.current_page,
                    total: data.total,
                    field: 'id',
                    order: 'descend'
                }
            },

            paginarResultados () {
                this.getRoles(this.paginate)
                .then(response => {
                    this.changePaginate(response.data.meta)
                }).catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            }
		},

        mounted () {
            this.paginarResultados()
            this.getPermission()
        }

	}
</script>

<style lang="scss">
    @import '~pretty-checkbox/src/pretty-checkbox.scss';

    .pretty {
        position: relative;
        display: grid;
        margin-right: 1em;
        white-space: nowrap;
        line-height: 1;
    }
</style>